var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_spa-container"},[(_vm.acceptingOffer)?_c('div',{staticClass:"_loading-screens"},[_c('loading-spinner',{attrs:{"width":100}})],1):_vm._e(),(!_vm.displayFinalResult)?_c('div',{staticClass:"_statement-content"},[_c('div',[(_vm.selectedOffer.company_name == 'Boodle')?_c('div',{staticClass:"_detailed-offer _selected_offer"},[_c('div',{staticClass:"_transparent-header _top-header"},[(_vm.selectedOffer.company_wide_logo_url !== null)?[_c('img',{attrs:{"src":_vm.selectedOffer.company_wide_logo_url,"alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]:(_vm.selectedOffer.company_logo_url !== null)?[_c('img',{attrs:{"src":_vm.selectedOffer.company_logo_url,"alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]:[_c('img',{attrs:{"src":"/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg","alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]],2),_c('div',{staticClass:"_offer-content"},[_c('span',{staticClass:"_grey-text"},[_vm._v("Details of your personalised loan offer:")]),(
              _vm.selectedOffer.live_score &&
                _vm.selectedOffer.live_score.ProductDescription
            )?_c('span',{staticClass:"_light-text",staticStyle:{"margin-top":"0px"}},[_vm._v(_vm._s(_vm.selectedOffer.live_score.ProductDescription))]):_vm._e(),_c('div',{staticClass:"_more-info"},[_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left",staticStyle:{"color":"white"}},[_vm._v("Loan Amount:")]),(
                  _vm.selectedOffer.live_score &&
                    _vm.selectedOffer.live_score.LoanAmount > 0
                )?_c('p',{staticClass:"right",staticStyle:{"color":"white"}},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.LoanAmount ))+" ")]):_vm._e()]),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.TotalFees > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left",staticStyle:{"color":"white","font-weight":"300","margin-top":"0"}},[_vm._v(" Total Interest & Fees: ")]),_c('p',{staticClass:"right",staticStyle:{"color":"white","font-weight":"300","margin-top":"0"}},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.TotalFees ))+" ")])]):_vm._e(),_c('div',{staticClass:"_section",staticStyle:{"border-top":"1px solid","margin-top":"5px","border-bottom":"1px solid","border-color":"rgb(255 255 255 / 43%)"}},[_c('p',{staticClass:"left",staticStyle:{"color":"white","margin-top":"5px","margin-bottom":"5px"}},[_vm._v(" Total Repayment: ")]),_c('p',{staticClass:"right",staticStyle:{"color":"white","margin-top":"5px","margin-bottom":"5px"}},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.TotalFees + _vm.selectedOffer.live_score.LoanAmount ))+" ")])]),(
                _vm.selectedOffer.live_score && _vm.selectedOffer.live_score.Term > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Loan Term:")]),(_vm.selectedOffer.live_score.PaydayLoan)?_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.Term == 1 ? _vm.selectedOffer.live_score.Term + " day" : _vm.selectedOffer.live_score.Term + " days")+" ")]):_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.Term == 1 ? _vm.selectedOffer.live_score.Term + " month" : _vm.selectedOffer.live_score.Term + " months")+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.InterestRate > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Interest Rate:")]),_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.InterestRate)+"% ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.TotalInterest > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Total interest to be paid:")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.TotalInterest ))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.InitiationFee > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Initiation fee:")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.InitiationFee ))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.TotalServiceFee > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Total service fee:")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.TotalServiceFee ))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.InsurancePremium > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Total insurance premium:")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.InsurancePremium ))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.RepaymentDate
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Payment due date:")]),_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.RepaymentDate)+" ")])]):_vm._e()])])]):(
          (_vm.selectedOffer.live_score != -2 && !_vm.selectedOffer.live_score) ||
            _vm.selectedOffer.id === 116
        )?_c('div',{staticClass:"_selected_offer"},[_c('div',{staticClass:"_top-header"},[(_vm.selectedOffer.company_wide_logo_url !== null)?[_c('img',{attrs:{"src":_vm.selectedOffer.company_wide_logo_url,"alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]:(_vm.selectedOffer.company_logo_url !== null)?[_c('img',{attrs:{"src":_vm.selectedOffer.company_logo_url,"alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]:[_c('img',{attrs:{"src":"/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg","alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]],2),_c('div',{staticClass:"_offer-content"},[(
              _vm.selectedOffer.live_score &&
                _vm.selectedOffer.live_score.LoanAmount > 0
            )?_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.LoanAmount )))]):(
              _vm.selectedOffer.live_score &&
                _vm.selectedOffer.live_score.min &&
                _vm.selectedOffer.live_score.max > 0
            )?_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.min))+" - R"+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.max)))]):_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format( _vm.personalLoan.loan_amount_required )))]),(
              _vm.selectedOffer.live_score &&
                _vm.selectedOffer.live_score.ProductDescription
            )?_c('span',{staticClass:"_light-text",staticStyle:{"margin-top":"0px"}},[_vm._v(_vm._s(_vm.selectedOffer.live_score.ProductDescription))]):_vm._e(),_c('span',{staticClass:"_light-text"},[_vm._v("Below is an estimate of the loan terms you may be eligible for:")]),_c('span',{staticClass:"_terms"},[_vm._v("Loan Terms")]),_c('div',{staticClass:"_more-info"},[(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.LoanAmount > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Principal Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R "+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.LoanAmount ))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score && _vm.selectedOffer.live_score.min > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Minimum Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R "+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.min))+" ")])]):_vm._e(),(
                _vm.selectedOffer.live_score && _vm.selectedOffer.live_score.max > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Maximum Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R "+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.max))+" ")])]):_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Principal Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.personalLoan.loan_amount_required ))+" ")])]),(_vm.selectedOffer.probability !== null)?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Chance of success")]),_c('p',{staticClass:"right"},[_vm._v(_vm._s(_vm.selectedOffer.probability)+"%")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.InterestRate > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Interest Rate")]),_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.InterestRate)+"% ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.TermAllowed > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Term up to")]),(_vm.selectedOffer.live_score.PaydayLoan)?_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.TermAllowed)+" Days ")]):_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.TermAllowed)+" Months ")])]):_vm._e(),(
                _vm.selectedOffer.live_score &&
                  _vm.selectedOffer.live_score.MonthlyRepayment > 0
              )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Monthly Installment")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.MonthlyRepayment ))+" ")])]):_vm._e()])])]):(_vm.selectedOffer.live_score != -2)?_c('div',{staticClass:"_selected_offer"},[_c('div',{staticClass:"_top-header"},[(_vm.selectedOffer.company_logo_url !== null)?[_c('img',{attrs:{"src":_vm.selectedOffer.company_logo_url,"alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]:[_c('img',{attrs:{"src":"/themes/rainlab-vanilla/assets/icons/finance/52-money-box.svg","alt":""},on:{"error":function($event){return _vm.handleImageLoadError($event)}}})]],2),_c('div',{staticClass:"_offer-content"},[(_vm.selectedOffer.live_score != null)?[(_vm.selectedOffer.live_score !== 'undefined')?[(
                  _vm.selectedOffer.live_score &&
                    _vm.selectedOffer.live_score.LoanAmount > 0
                )?_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.LoanAmount )))]):(
                  _vm.selectedOffer.live_score &&
                    _vm.selectedOffer.live_score.min &&
                    _vm.selectedOffer.live_score.max > 0
                )?_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.min))+" - R"+_vm._s(new Intl.NumberFormat().format(_vm.selectedOffer.live_score.max)))]):_c('span',{staticClass:"_big-loan-amount"},[_vm._v("R"+_vm._s(new Intl.NumberFormat().format( _vm.personalLoan.loan_amount_required )))])]:_vm._e()]:_vm._e(),(
              _vm.selectedOffer.live_score &&
                _vm.selectedOffer.live_score.ProductDescription
            )?_c('span',{staticClass:"_light-text",staticStyle:{"margin-top":"0px"}},[_vm._v(_vm._s(_vm.selectedOffer.live_score.ProductDescription))]):_vm._e(),_c('span',{staticClass:"_light-text"},[_vm._v("Below is an estimate of the loan terms you may be eligible for:")]),_c('span',{staticClass:"_terms"},[_vm._v("Loan Terms")]),_c('div',{staticClass:"_more-info"},[(_vm.selectedOffer.live_score !== 'undefined')?[(_vm.selectedOffer.live_score.LoanAmount)?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Principal Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.LoanAmount ))+" ")])]):_vm._e(),(
                  _vm.selectedOffer.live_score.InterestRate &&
                    _vm.selectedOffer.live_score.InterestRate > 0
                )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Interest rate")]),(_vm.selectedOffer.id == 150)?_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.InterestRate)+"% Monthly ")]):_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.InterestRate)+"% ")])]):_vm._e(),(
                  _vm.selectedOffer.live_score && _vm.selectedOffer.live_score.min > 0
                )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Minimum Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R "+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.min ))+" ")])]):_vm._e(),(
                  _vm.selectedOffer.live_score && _vm.selectedOffer.live_score.max > 0
                )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Maximum Amount")]),_c('p',{staticClass:"right"},[_vm._v(" R "+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.max ))+" ")])]):_vm._e(),(_vm.selectedOffer.live_score.TermAllowed)?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Term up to")]),_c('p',{staticClass:"right"},[_vm._v(" "+_vm._s(_vm.selectedOffer.live_score.TermAllowed)+" Months ")])]):_vm._e(),(
                  _vm.selectedOffer.live_score &&
                    _vm.selectedOffer.live_score.MonthlyRepayment > 0
                )?_c('div',{staticClass:"_section"},[_c('p',{staticClass:"left"},[_vm._v("Monthly Installment")]),_c('p',{staticClass:"right"},[_vm._v(" R"+_vm._s(new Intl.NumberFormat().format( _vm.selectedOffer.live_score.MonthlyRepayment ))+" ")])]):_vm._e()]:_vm._e()],2)],2)]):_vm._e()]),(_vm.type == 'apply-now' && _vm.selectedOffer.company_name == 'Boodle')?_c('span',{staticStyle:{"text-align":"center","color":"#A8ACB8"}},[_vm._v("By clicking ‘Accept & proceed’ you agree to "+_vm._s(_vm.selectedOffer.company_name)+"’s "),_c('br'),_c('span',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.goToTerms(_vm.selectedOffer.company_name)}}},[_vm._v("Terms & Conditons")]),_vm._v(" and "),_c('span',{staticStyle:{"text-decoration":"underline"},on:{"click":function($event){return _vm.goToPrivacy(_vm.selectedOffer.company_name)}}},[_vm._v("Privacy Policy")]),_vm._v(" .")]):_vm._e(),_c('div',[(_vm.type == 'apply-now')?_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","flex-direction":"column","padding-top":"20px"}},[_c('primary-button',{staticStyle:{"width":"100%","margin-left":"0px","justify-content":"center"},attrs:{"id":"primary_nav","type":"button"},on:{"click":function($event){return _vm.acceptOffer(
              _vm.selectedOffer.id,
              _vm.selectedOffer.live_score ? _vm.selectedOffer.live_score : null
            )}}},[_c('span',[_vm._v("Accept & proceed")])]),_c('secondary-button',{staticStyle:{"width":"100%","margin-top":"10px"},attrs:{"id":"secondary_nav"},on:{"click":_vm.goBack}},[_vm._v("Back to offers")])],1):_c('div',{staticStyle:{"display":"flex","width":"100%","justify-content":"space-between","flex-direction":"column"}},[[(
              _vm.selectedOffer.userable.partner_type == 'api' ||
                _vm.selectedOffer.userable.partner_type == 'dashboard'
            )?_c('primary-button',{staticStyle:{"width":"100%","margin-left":"0px","justify-content":"center"},attrs:{"id":"primary_nav","type":"button"},on:{"click":function($event){return _vm.acceptOffer(
                _vm.selectedOffer.id,
                _vm.selectedOffer.live_score ? _vm.selectedOffer.live_score : null
              )}}},[_c('span',[_vm._v("Complete Application")])]):(_vm.selectedOffer.userable.partner_type == 'url')?_c('primary-button',{staticStyle:{"width":"100%","margin-left":"0px","justify-content":"center"},attrs:{"id":"primary_nav","type":"button"},on:{"click":function($event){return _vm.acceptOffer(_vm.selectedOffer.id)}}},[_vm._v("Complete Application")]):_vm._e()],_c('secondary-button',{staticStyle:{"width":"100%","margin-left":"0px","margin-top":"10px"},attrs:{"id":"secondary_nav"},on:{"click":_vm.goBack}},[_vm._v("Back to offers")])],2)])]):(_vm.displayFinalResult && _vm.partnerStatus == false)?_c('div',{staticStyle:{"height":"calc(100vh - 44px)","display":"flex","flex-direction":"column","justify-content":"space-between"}},[_vm._m(0),_c('div',{staticClass:"_button-container",staticStyle:{"flex-direction":"column"}},[_c('primary-button',{staticStyle:{"width":"100%","margin-left":"0px"},attrs:{"id":"primary_nav"},on:{"click":_vm.redirectToFaqs}},[_vm._v("FAQ's")]),_c('secondary-button',{staticStyle:{"width":"100%","justify-content":"center","margin-left":"0px","margin-top":"10px"},attrs:{"id":"secondary_nav"},on:{"click":_vm.reportIssue}},[_vm._v("Report Issue")])],1)]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_wording-container",staticStyle:{"margin-top":"60px"}},[_c('img',{attrs:{"src":"/assets/spa/fail-envelop.svg"}}),_c('span',{staticClass:"_title",staticStyle:{"margin-top":"20px"}},[_vm._v("Application declined")]),_c('span',{staticClass:"_text",staticStyle:{"padding":"0px 25px"}},[_vm._v("Unfortunately your application was declined by the lender.")])])
}]

export { render, staticRenderFns }